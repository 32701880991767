html,
body {
  /* background-color: #0a0a0a; */
  background-color: #121212;
  overflow-x: hidden;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: "Genos";
  src: url("./fonts/Genos-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GenosBlack";
  src: url("./fonts/Genos-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NicoMoji";
  src: url("./fonts/NicoMoji-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Monsieur";
  src: url("./fonts/MonsieurLaDoulaise-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

a,
p,
h1,
h2 {
  color: #f1f1f1;
}

.navbar {
  margin: 2% 15%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: center;
  color: #f1f1f1;
}

.nav-logo {
  width: 70px;
  display: flex;
  justify-content: center;
}

.nav-links {
  display: flex;
  list-style: none;
  flex: 2;
  justify-content: center;
}

@media screen and (max-width: 930px) {
  .project-desc {
    display: none;
  }
}

.nav-links li {
  margin: 0 1rem;
  /* border: 1px solid green; */
}

.nav-links a {
  transition: all 0.2s ease-in-out;
}

.nav-links a:hover {
  color: #cc0003;
}

.nav-links a {
  color: #ff060a;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 600;
}

.landing-name h1 {
  /* font-family: "NicoMoji", Arial, sans-serif; */
  font-family: "GenosBlack";
  font-size: 17vw;
  text-align: center;
  margin-top: 6vw;
  transform: scaleY(1.2);
  font-weight: 100;
  z-index: 10;
  filter: blur(0.5px);

  color: #ff060a;
}

.landing-name h2 {
  font-family: "Monsieur", Arial, sans-serif;
  font-size: 11vw;
  /* letter-spacing: -0.5vw; */
  margin-top: -30vw;
  text-align: center;
  margin-right: 2rem;
  transform: scaleY(2);
  font-weight: 100;
  color: #121212;
  /* color: #5d0405; */

  /* color: #353535; */
  /* 
  text-shadow: 0 0 1px #3e3e3e, 0 0 11px #3e3e3e, 0 0 1px #010101,
    0 0 1px #010101, 0 0 4px #010101, 0 0 1px #010101, 0 0 1px #010101; */
  /* color: #2d0000; */
  text-shadow: 0 0 1px #ff0404b4, 0 0 11px #ff0404b4, 0 0 1px #420000,
    0 0 1px #bbbbbb, 0 0 4px #040404, 0 0 1px #420000, 0 0 1px #420000;
  z-index: -10;
  filter: blur(0.2px);
  opacity: 0.5;
}

.loading-screen h3 {
  font-family: "Monsieur", Arial, sans-serif;
  color: #121212;
  position: absolute;
}

@media screen and (max-width: 425px) {
  .landing-name h2 {
    opacity: 0.7;
  }
  .landing-name h1 {
    position: relative;
    z-index: 1;
  }
}

.landing-name {
  text-align: center;
}

.landing-bio {
  margin: 0 15%;
  width: 60%;
  text-align: right;
  margin-left: auto;
  font-weight: 600;
  font-size: 1.2rem;
  min-height: 70px;
  max-width: 400px;
  padding: 20px;
  position: relative;
}

.landing-bio p,
.typewriter,
.Typewriter__wrapper {
  color: #ff060a;
}

.corner-shape {
  width: 15px;
  height: 15px;
  background-color: #828282;
  clip-path: polygon(0% 0%, 100% 0%, 100% 20%, 20% 20%, 20% 100%, 0% 100%);
  position: absolute;
}

.corners {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.top-left {
  top: 0;
  left: 0;
}

.top-right {
  top: 0;
  right: 0;
  transform: rotate(90deg);
}

.bottom-left {
  bottom: 0;
  left: 0;
  transform: rotate(270deg);
}

.bottom-right {
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}

#landing {
  margin: 0;
}

section {
  margin: 3% 15%;
}

.projects-heading {
  margin-top: 2%;
  color: #ff060a;
  font-size: 10vw;
  font-family: "GenosBlack", Arial, sans-serif;
  font-weight: bolder;
  letter-spacing: -5px;
  padding-left: 20px;
}

.marquee {
  display: flex;
}

.projects {
  display: flex;
  flex-direction: column;
  margin-bottom: 15%;
}

.projects tr {
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
}

.projects table {
  font-weight: bold;
  color: #f1f1f1;
  font-size: 2rem;
  width: 100%;
  border-collapse: collapse;
}

.projects td {
  padding: 10px 0;
  position: relative;
}

.projects tr td:nth-child(1) {
  padding-right: 1.2rem;
}

.projects td::before,
.projects td::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #f1f1f1;
  transition: transform 0.3s ease;
}

.projects td::before {
  top: 0;
  transform: scaleX(0);
}

.projects td::after {
  bottom: 0;
  transform: scaleX(0);
}

.projects tr:hover td::before,
.projects tr:hover td::after {
  transform: scaleX(1);
}

.projects tr:not(:hover) {
  opacity: 0.5;
}

.projects tr:hover {
  position: relative;
  transition: opacity 0.3s ease;
  z-index: 9999;
}

.hover-image {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-90%, -60%);
  z-index: 999;
  max-height: 600px;
}

.hover-image,
.hover-image-middle,
.hover-image-right {
  will-change: transform, opacity;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.hover-image-middle {
  position: fixed;
  top: 50%;
  left: 45%;
  transform: translate(-30%, -50%);
  z-index: 999;
}
.hover-image-right {
  position: fixed;
  top: 50%;
  left: 45%;
  z-index: 998;
}

.hover-image-right img {
  max-width: 50%;
  height: auto;
}

.hover-image-middle video,
.hover-image-middle img {
  max-width: 70%;
  height: auto;
  max-height: 900px;
}

.hover-image img {
  max-width: 50%;
  height: auto;
}

.hover-image,
.hover-image-middle,
.hover-image-right {
  pointer-events: none;
}

.about-section h3 {
  color: #ff060a;
  font-weight: 600;
  margin: 0;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  font-size: 1.5rem;
}

.about-section table {
  color: #f1f1f1;
  margin-bottom: 2rem;
}

.socials-section {
  margin-bottom: 2rem;
}

.about-section table tr td:first-child {
  padding-right: 2rem;
}

.about-section table tr td:nth-child(2),
.about-section table tr td:nth-child(3) {
  padding-right: 1.5rem;
}

.about-heading {
  display: flex;
}
.about-heading img {
  scale: 0.8;
  rotate: 270deg;
}

.about-section {
  display: flex;
  flex-direction: column;
  gap: 10%;
  padding-bottom: 5%;
}

.about-section p,
.about-section a {
  margin: 0;
}

@media screen and (max-width: 825px) {
  .about-section table tr td:nth-child(2) {
    display: none;
  }
}

@media screen and (max-width: 610px) {
  .about-section table tr td:nth-child() {
    display: none;
  }
}

.socials {
  display: flex;
}

.socials img {
  scale: 0.6;
}

.about-left {
  min-width: 360px;
}

.about-right {
  font-size: 1.2rem;
  max-width: 600px;
}

.socials img {
  transition: transform 0.6s;
}

.socials img {
  transform: rotate(0deg);
}

.socials:hover img {
  transform: rotate(45deg);
  transition: transform 0.4s;
}

.socials {
  width: fit-content;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 425px) {
  .navbar {
    margin: 2% 5%;
  }
  section {
    margin: 3% 5%;
  }
}

@media screen and (max-width: 620px) {
  .landing-bio {
    font-size: 14px;
    padding-right: 5%;
    width: 90%;
  }

  .corners {
    display: none;
  }
  .nav-links a {
    font-size: 14px;
  }
  .navbar {
    padding-top: 20px;
  }
  .nav-logo {
    display: none;
  }
  .projects table {
    font-size: 16px;
  }
  .nav-links {
    padding-left: 0;
  }
}

/* loading screen */
.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #121212;
}

.progress-bar {
  width: 90%;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
}

.progress {
  height: 100%;
  background-color: #ff060a;
  width: 0%;
  transition: width 0.3s ease-in-out;
}

#rightimg-1 {
}

#middlevideo-1 {
}

#leftimg-1 {
}

#leftimg-1,
#rightimg-1 {
  scale: 3;
  max-height: 800px;
}

.motion-video {
  width: 100%;
}

#back-btn {
  padding: 10px;
  padding-left: 0px;
  cursor: pointer;
  font-size: 18px;
  color: #cc0003;
}

.project-imgs img {
  width: 100%;
}

#traffic-img {
  width: 40%;
}

.ewg-redesign-section p {
  max-width: 600px;
  font-size: 18px;
}
